import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { type UseQueryResult } from "@tanstack/react-query";

import { type GetWorkplaceQuizResponse, getWorkplaceQuizResponseSchema } from "../../types";

export function getWorkplaceQuizUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/workplaces/${workplaceId}/quizzes`;
}

/**
 * We always need the latest quiz, hence, sort by createdAt (descending) and limit to 1
 * We always need the notes for the quiz, hence, include notes
 */
export function getWorkplaceQuizQueryParams() {
  return { page: { size: 1 }, sort: "-createdAt", include: "notes" };
}

export function useGetWorkplaceQuiz(
  workplaceId: string,
  options: UseGetQueryOptions<GetWorkplaceQuizResponse> = {}
): UseQueryResult<GetWorkplaceQuizResponse> {
  return useGetQuery({
    url: `${getWorkplaceQuizUrl(workplaceId)}`,
    // We need latest single quiz so passing pageSize = 1
    queryParams: getWorkplaceQuizQueryParams(),
    responseSchema: getWorkplaceQuizResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_QUIZ_FAILURE,
      userErrorMessage:
        "Something went wrong while loading the quiz. Please retry or contact support if the error persists.",
    },
    ...options,
  });
}
