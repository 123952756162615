import { ModalStatus, useModalState } from "@clipboard-health/ui-react";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, CardActions, List, ListItem, useTheme } from "@mui/material";
import { FacilityDetailsCollapse } from "@src/appV2/Facilities/components/FacilityDetailsCollapse";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useSearchParams } from "@src/appV2/lib/utils/useSearchParams";
import { useGetWorkplaceQuizResultLogs } from "@src/appV2/WorkplaceQuiz/api/useGetWorkplaceQuizResultLogs/useGetWorkplaceQuizResultLogs";

import { QuizPassedDialog, type QuizPassedDialogProps } from "../Dialogs/QuizPassedDialog";
import { type WorkplaceQuizData } from "../types";
import {
  WorkplaceRulesWithQuiz,
  type WorkplaceRulesWithQuizProps,
} from "../WorkplaceRulesWithQuiz/WorkplaceRulesWithQuiz";

interface WorkplaceImportantInformationProps
  extends Pick<QuizPassedDialogProps, "workplace">,
    Pick<WorkplaceRulesWithQuizProps, "facilityNotes"> {
  quizData: WorkplaceQuizData;
}

export function WorkplaceImportantInformation(props: WorkplaceImportantInformationProps) {
  const { facilityNotes, workplace, quizData } = props;
  const searchParams = useSearchParams();
  const workplaceQuizDialogModalState = useModalState(
    searchParams?.workplaceRulesState === "open" ? ModalStatus.OPEN : ModalStatus.CLOSED
  );
  const quizPassedDialogState = useModalState();
  const theme = useTheme();
  const facilitySummary = quizData.attributes.summary;

  const { data: workplaceQuizResultLogResponse, refetch: refetchQuizResultLogs } =
    useGetWorkplaceQuizResultLogs({
      workplaceId: quizData.attributes.workplaceId,
      quizId: quizData.id,
      queryParams: {
        pageSize: 1,
        sort: "-createdAt",
      },
    });

  return (
    <>
      <FacilityDetailsCollapse
        isExpandedByDefault
        disableToggle
        icon={<ErrorIcon sx={{ color: theme.palette.error.dark }} />}
        title="Important Information"
      >
        <List
          dense
          disablePadding
          sx={{
            listStyleType: "disc",
            listStylePosition: "outside",
          }}
        >
          {facilitySummary.map((summaryItem) => {
            return (
              <ListItem
                key={summaryItem}
                disablePadding
                sx={{
                  display: "list-item",
                }}
              >
                {summaryItem}
              </ListItem>
            );
          })}
        </List>

        <CardActions
          sx={{
            padding: 0,
            flexDirection: "column",
          }}
        >
          <Button
            variant="text"
            size="small"
            endIcon={<ExpandMoreIcon />}
            onClick={() => {
              workplaceQuizDialogModalState.openModal();
              logEvent(APP_V2_USER_EVENTS.VIEWED_WORKPLACE_RULES_PAGE, {
                workplaceId: quizData.attributes.workplaceId,
                source: "facility_page",
              });
            }}
          >
            View more information
          </Button>
        </CardActions>
      </FacilityDetailsCollapse>

      <WorkplaceRulesWithQuiz
        facilityName={workplace.name}
        quiz={quizData}
        latestQuizResultLog={workplaceQuizResultLogResponse?.data?.[0]}
        quizRulesDialog={workplaceQuizDialogModalState}
        facilityNotes={facilityNotes}
        logMetadata={{
          source: "facility_page",
        }}
        refetchQuizResultLogs={refetchQuizResultLogs}
        onQuizPassed={() => {
          quizPassedDialogState.openModal();
        }}
      />

      <QuizPassedDialog
        workplace={workplace}
        modalState={quizPassedDialogState}
        onContinue={() => {
          quizPassedDialogState.closeModal();
        }}
      />
    </>
  );
}
