import { type UseModalState } from "@clipboard-health/ui-react";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type useGetWorkplaceQuizMutation } from "@src/appV2/WorkplaceQuiz/api/useGetWorkplaceQuizMutation/useGetWorkplaceQuizMutation";
import { type usePostQuizView } from "@src/appV2/WorkplaceQuiz/api/usePostQuizView/usePostQuizView";
import { type WorkplaceRulesWithQuizProps } from "@src/appV2/WorkplaceQuiz/WorkplaceRulesWithQuiz/WorkplaceRulesWithQuiz";

interface OpenQuizDialogModalParams {
  workplaceId: string;
  shiftId: string;
  getWorkplaceQuiz: ReturnType<typeof useGetWorkplaceQuizMutation>["mutateAsync"];
  postQuizView: ReturnType<typeof usePostQuizView>["mutate"];
  source: WorkplaceRulesWithQuizProps["logMetadata"]["source"];
  quizRequirementDialogModal: UseModalState;
}

export async function openQuizDialogModal(params: OpenQuizDialogModalParams) {
  const {
    workplaceId,
    shiftId,
    getWorkplaceQuiz,
    postQuizView,
    source,
    quizRequirementDialogModal,
  } = params;

  // The quiz data is not directly used in this scope
  // but rather used from the mutation result
  const { data: workplaceQuizData } = await getWorkplaceQuiz({ workplaceId });
  quizRequirementDialogModal.openModal();
  logEvent(APP_V2_USER_EVENTS.VIEWED_QUIZ_REQUIREMENT_DIALOG, {
    shiftId,
    workplaceId,
    source,
  });
  postQuizView({
    shiftId,
    workplaceId,
    quizId: workplaceQuizData?.[0]?.id ?? "",
  });

  return null;
}
