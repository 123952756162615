import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import {
  type GetWorkplaceQuizResultLogsResponse,
  getWorkplaceQuizResultLogsResponseSchema,
} from "@src/appV2/WorkplaceQuiz/types";
import { type UseQueryResult } from "@tanstack/react-query";

interface GetWorkplaceQuizResultLogsQueryParams {
  pageSize: number;
  sort: string;
}

export function getWorkplaceQuizResultLogsUrl(workplaceId: string, quizId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/workplaces/${workplaceId}/quizzes/${quizId}/result-logs`;
}

export function getWorkplaceQuizResultLogsQueryParams(
  params: GetWorkplaceQuizResultLogsQueryParams
) {
  const { pageSize, sort } = params;
  return { page: { size: pageSize }, sort };
}

export function useGetWorkplaceQuizResultLogs(params: {
  workplaceId: string;
  quizId: string;
  queryParams: GetWorkplaceQuizResultLogsQueryParams;
  options?: UseGetQueryOptions<GetWorkplaceQuizResultLogsResponse>;
}): UseQueryResult<GetWorkplaceQuizResultLogsResponse> {
  const { workplaceId, quizId, options = {}, queryParams } = params;
  return useGetQuery({
    url: `${getWorkplaceQuizResultLogsUrl(workplaceId, quizId)}`,
    queryParams: getWorkplaceQuizResultLogsQueryParams(queryParams),
    responseSchema: getWorkplaceQuizResultLogsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_QUIZ_RESULT_LOGS_FAILURE,
      userErrorMessage:
        "Something went wrong while loading the results. Please retry or contact support if the error persists.",
    },
    ...options,
  });
}
