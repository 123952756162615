import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Alert, Button, Stack } from "@mui/material";
import { type FacilityNote, FacilityNoteCategoryIdentifier } from "@src/appV2/Facilities/types";
import {
  type WorkplaceQuizResultLogSchema,
  WorkplaceQuizResultStatus,
} from "@src/appV2/WorkplaceQuiz/types";

export interface WorkplaceRulesProps {
  facilityNotes: Array<
    Pick<
      FacilityNote,
      "isVisibleToWorker" | "categoryTextVisibleToWorker" | "noteId" | "note" | "identifier"
    >
  >;
  latestQuizResultLog?: WorkplaceQuizResultLogSchema;
  onStartQuiz: () => void;
}

export function WorkplaceRules(props: WorkplaceRulesProps) {
  const { facilityNotes, latestQuizResultLog, onStartQuiz: onClickStartQuiz } = props;
  return (
    <>
      <Stack spacing={2} flexGrow={1}>
        <Alert variant="filled" severity="info" sx={{ marginTop: 3 }}>
          <Text color="inherit">
            Review the following facility information before continuing to take the quiz.
          </Text>
        </Alert>
        {facilityNotes
          .filter(
            ({ note, isVisibleToWorker, identifier }) =>
              isVisibleToWorker &&
              identifier !== FacilityNoteCategoryIdentifier.MANDATORY_BREAK_POLICY &&
              isDefined(note) &&
              note.trim().length > 0
          )
          .map(({ noteId, categoryTextVisibleToWorker, note }) => (
            <Text key={noteId}>
              <b>{categoryTextVisibleToWorker}: </b> {note}
            </Text>
          ))}
      </Stack>
      <Button fullWidth sx={{ marginTop: 3 }} variant="contained" onClick={onClickStartQuiz}>
        {isDefined(latestQuizResultLog) &&
        latestQuizResultLog.attributes.newStatus === WorkplaceQuizResultStatus.PASSED
          ? `Passed: ${(latestQuizResultLog.attributes.stats.scorePercentage * 100).toFixed(
              0
            )}% Correct`
          : "Start Quiz"}
      </Button>
    </>
  );
}
