interface UseIsQuizWorkersOnWorkplaceRulesFeatureEnabledProps {
  workplace: {
    quizWorkersOnRules?: boolean;
  };
}

export function useIsQuizWorkersOnWorkplaceRulesFeatureEnabled(
  parameters: UseIsQuizWorkersOnWorkplaceRulesFeatureEnabledProps
): boolean {
  const { workplace } = parameters;
  return Boolean(workplace.quizWorkersOnRules);
}
