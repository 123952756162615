import { type ApiResponse, post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import {
  postQuizResultRequestSchema,
  type PostQuizResultResponse,
  postQuizResultResponseSchema,
  type WorkplaceQuizOption,
  type WorkplaceQuizQuestion,
  WorkplaceQuizResource,
} from "../../types";

export function getSubmitWorkplaceQuizUrl(workplaceId: string, quizId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/workplaces/${workplaceId}/quizzes/${quizId}/results?include=latestQuizResultLog`;
}

interface SubmitQuizMutationFnParams {
  workplaceId: string;
  quizId: string;
  answers: Record<WorkplaceQuizQuestion["id"], WorkplaceQuizOption["id"]>;
}

export function useSubmitWorkplaceQuiz(
  options: UseMutationOptions<
    ApiResponse<PostQuizResultResponse>,
    AxiosError,
    SubmitQuizMutationFnParams
  > = {}
): UseMutationResult<ApiResponse<PostQuizResultResponse>, AxiosError, SubmitQuizMutationFnParams> {
  return useMutation({
    mutationFn: async (params) => {
      const { quizId, workplaceId, answers } = params;
      return await post({
        url: getSubmitWorkplaceQuizUrl(workplaceId, quizId),
        data: {
          data: {
            type: WorkplaceQuizResource.WORKPLACE_QUIZ_RESULT,
            attributes: {
              answers: Object.entries(answers).map(([questionId, optionId]) => ({
                questionId,
                optionId,
              })),
            },
          },
        },
        requestSchema: postQuizResultRequestSchema,
        responseSchema: postQuizResultResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.SUBMIT_WORKPLACE_QUIZ_FAILURE,
    },
    retry: 2,
    ...options,
  });
}
