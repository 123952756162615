import { type ApiResponse, post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

export function getQuizViewUrl(workplaceId: string, quizId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/workplaces/${workplaceId}/quizzes/${quizId}/views`;
}

const postQuizViewResponseSchema = z.object({
  data: z.object({
    id: z.string(),
  }),
});

type PostQuizViewResponse = z.infer<typeof postQuizViewResponseSchema>;

interface UsePostQuizViewParams {
  workplaceId: string;
  shiftId: string;
  quizId: string;
}

export function usePostQuizView(
  options: UseMutationOptions<
    ApiResponse<PostQuizViewResponse>,
    AxiosError,
    UsePostQuizViewParams
  > = {}
): UseMutationResult<ApiResponse<PostQuizViewResponse>, AxiosError, UsePostQuizViewParams> {
  return useMutation({
    mutationFn: async (params) => {
      const { shiftId, workplaceId, quizId } = params;
      return await post({
        url: getQuizViewUrl(workplaceId, quizId),
        data: {
          data: {
            type: "quiz-view",
            attributes: {
              shiftId,
            },
          },
        },
        responseSchema: postQuizViewResponseSchema,
      });
    },
    ...options,
  });
}
