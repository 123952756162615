import { baseFacilityNoteSchema } from "@src/appV2/Facilities/types";
import { z } from "zod";

export enum WorkplaceQuizResource {
  WORKPLACE_QUIZ_RESULT = "WorkplaceQuizResult",
}

const optionSchema = z.object({
  id: z.string(),
  text: z.string().min(1),
  isCorrect: z.boolean(),
});

export const workplaceQuizQuestionSchema = z.object({
  id: z.string(),
  text: z.string().min(1),
  options: z.array(optionSchema).min(2),
});

export type WorkplaceQuizQuestion = z.infer<typeof workplaceQuizQuestionSchema>;
export type WorkplaceQuizOption = z.infer<typeof optionSchema>;

export const workplaceQuizSchema = z.object({
  summary: z.array(z.string()),
  workplaceId: z.string().min(1),
  questions: z.array(workplaceQuizQuestionSchema).min(1),
  passPercentage: z.number().min(0).max(1),
});
export type WorkplaceQuiz = z.infer<typeof workplaceQuizSchema>;

export const workplaceQuizDataSchema = z.object({
  attributes: workplaceQuizSchema,
  id: z.string().min(1),
  type: z.string(),
});

export type WorkplaceQuizData = z.infer<typeof workplaceQuizDataSchema>;

export const PostQuizResultApiRequestAttributes = z.object({
  answers: z.array(
    z.object({
      questionId: z.string(),
      optionId: z.string(),
    })
  ),
});
export const postQuizResultRequestSchema = z.object({
  data: z.object({
    attributes: PostQuizResultApiRequestAttributes,
    type: z.literal(WorkplaceQuizResource.WORKPLACE_QUIZ_RESULT),
  }),
});
export type PostQuizResultApiRequest = z.infer<typeof postQuizResultRequestSchema>;

export enum WorkplaceQuizResultStatus {
  PASSED = "PASSED",
  FAILED = "FAILED",
}

const workplaceQuizResultLogSchema = z.object({
  newStatus: z.nativeEnum(WorkplaceQuizResultStatus),
  questions: z.array(workplaceQuizQuestionSchema).min(1),
  quizId: z.string(),
  responses: z.array(
    z.object({
      questionId: z.string(),
      optionId: z.string(),
    })
  ),
  stats: z.object({
    scorePercentage: z.number(),
    passPercentage: z.number(),
  }),
  statusChangeTime: z.string(),
});

const workplaceQuizResultLogDataSchema = z.object({
  id: z.string(),
  type: z.string(),
  attributes: workplaceQuizResultLogSchema,
});

export type WorkplaceQuizResultLogSchema = z.infer<typeof workplaceQuizResultLogDataSchema>;

export const getWorkplaceQuizResultLogsResponseSchema = z.object({
  data: z.array(workplaceQuizResultLogDataSchema),
});

export type GetWorkplaceQuizResultLogsResponse = z.infer<
  typeof getWorkplaceQuizResultLogsResponseSchema
>;

export const workplaceQuizResultSchema = z.object({
  status: z.nativeEnum(WorkplaceQuizResultStatus),
});
export type WorkplaceQuizResult = z.infer<typeof workplaceQuizResultSchema>;

const latestWorkplaceQuizResultLogRelationshipSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.string(),
  }),
});

export const postQuizResultResponseSchema = z.object({
  data: z.object({
    attributes: workplaceQuizResultSchema,
    id: z.string(),
    type: z.string(),
    relationships: z
      .object({
        latestQuizResultLog: latestWorkplaceQuizResultLogRelationshipSchema.optional(),
      })
      .optional(),
  }),
  included: z.array(workplaceQuizResultLogDataSchema).optional(),
});
export type PostQuizResultResponse = z.infer<typeof postQuizResultResponseSchema>;

export const getWorkplaceQuizRequestSchema = z
  .object({
    page: z
      .object({
        size: z.number().optional(),
      })
      .optional(),
    sort: z.string().optional(),
    include: z.string().optional(),
  })
  .optional();

export const getWorkplaceQuizResponseSchema = z.object({
  data: z.array(workplaceQuizDataSchema),
  included: z
    .array(
      z.object({
        id: z.string(),
        type: z.string(),
        attributes: baseFacilityNoteSchema,
      })
    )
    .optional(),
});

export type GetWorkplaceQuizResponse = z.infer<typeof getWorkplaceQuizResponseSchema>;

export const quizWorkersOnWorkplaceRulesFeatureFlagSchema = z.object({
  global: z.boolean().optional(),
  ids: z.array(z.string()).optional(),
  msas: z.array(z.string()).optional(),
  states: z.array(z.string()).optional(),
});

export type QuizWorkersOnWorkplaceRulesFeatureFlagType = z.infer<
  typeof quizWorkersOnWorkplaceRulesFeatureFlagSchema
>;
