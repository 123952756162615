import { type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LegacyTwoButtonDialogActions } from "@src/appV2/lib/Dialogs/LegacyDialogActions";

interface QuizSubmissionErrorDialogProps {
  modalState: UseModalState;
  onCancel: () => void;
  onRetry: () => void;
}

export function QuizSubmissionErrorDialog(props: QuizSubmissionErrorDialogProps) {
  const { modalState, onCancel, onRetry: onTryAgain } = props;
  return (
    <Dialog open={modalState.modalIsOpen} onClose={modalState.closeModal}>
      <DialogTitle>We detected an error</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText>
          We experienced an error when grading your quiz. Please tap “Try Again” to submit your quiz
          again so you can book the shift or “Cancel” to view other open shifts.
        </DialogContentText>
      </DialogContent>
      <LegacyTwoButtonDialogActions
        leftAction={<Button onClick={onCancel}>Cancel</Button>}
        rightAction={<Button onClick={onTryAgain}>Try Again</Button>}
      />
    </Dialog>
  );
}
