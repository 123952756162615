import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts/src/lib/defined-utils";
import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import { RadioField } from "@src/appV2/lib/FormFields/RadioField/RadioField";
import { type ChangeEvent } from "react";
import { useController, type UseControllerProps } from "react-hook-form";

import {
  type WorkplaceQuizOption,
  type WorkplaceQuizQuestion,
  WorkplaceQuizResultStatus,
} from "../types";

type OptionId = WorkplaceQuizOption["id"];
type QuestionId = WorkplaceQuizQuestion["id"];

interface QuizQuestionProps extends UseControllerProps<Record<QuestionId, OptionId>> {
  question: WorkplaceQuizQuestion;
  userAnswers: Record<string, string>;
  questionIdToCorrectOptionIdMap: Record<string, string>;
  quizResult?: WorkplaceQuizResultStatus;
}

function getQuizOptionLabel(props: {
  option: { id: string; text: string; isCorrect: boolean };
  correctOptionId: string;
  question: WorkplaceQuizQuestion;
  userAnswers: Record<string, string>;
  quizResult?: WorkplaceQuizResultStatus;
}) {
  const { option, correctOptionId, quizResult, userAnswers, question } = props;

  if (
    quizResult !== WorkplaceQuizResultStatus.PASSED ||
    !isDefined(userAnswers[question.id]) ||
    userAnswers[question.id] === correctOptionId
  ) {
    return <Text>{option.text}</Text>;
  }

  if (option.id === userAnswers[question.id] && !option.isCorrect) {
    return (
      <Text
        sx={{
          color: (theme) => theme.palette.error.main,
        }}
      >
        <strong>Incorrect</strong>: {option.text}
      </Text>
    );
  }

  if (option.isCorrect) {
    return (
      <Text
        sx={{
          color: (theme) => theme.palette.success.main,
        }}
      >
        <strong>Correct</strong>: {option.text}
      </Text>
    );
  }

  return <Text>{option.text}</Text>;
}

export function QuizQuestion(props: QuizQuestionProps) {
  const { name, control, question, userAnswers, questionIdToCorrectOptionIdMap, quizResult } =
    props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control,
  });
  const correctOptionId = questionIdToCorrectOptionIdMap[question.id];

  return (
    <FormControl>
      <FormLabel id={question.id}>
        <Text bold>{question.text}</Text>
      </FormLabel>
      <RadioGroup
        aria-labelledby={question.id}
        name={question.id}
        value={field.value}
        sx={{
          paddingX: 1,
        }}
        onChange={(_: ChangeEvent<HTMLInputElement>, newValue: string) => {
          field.onChange(newValue);
        }}
      >
        {question.options.map((option) => (
          <RadioField
            key={option.id}
            value={option.id}
            disabled={
              isSubmitting ||
              (isDefined(userAnswers[question.id]) && userAnswers[question.id] === correctOptionId)
            }
            label={getQuizOptionLabel({
              option,
              correctOptionId,
              quizResult,
              question,
              userAnswers,
            })}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
