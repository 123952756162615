import { get } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  getWorkplaceQuizQueryParams,
  getWorkplaceQuizUrl,
} from "@src/appV2/WorkplaceQuiz/api/useGetWorkplaceQuiz/useGetWorkplaceQuiz";
import {
  getWorkplaceQuizRequestSchema,
  type GetWorkplaceQuizResponse,
  getWorkplaceQuizResponseSchema,
} from "@src/appV2/WorkplaceQuiz/types";
import { useMutation, type UseMutationResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";

interface UseGetWorkplaceQuizMutationProps {
  workplaceId: string;
}

export function useGetWorkplaceQuizMutation(): UseMutationResult<
  GetWorkplaceQuizResponse,
  AxiosError,
  UseGetWorkplaceQuizMutationProps
> {
  return useMutation({
    mutationFn: async (props: UseGetWorkplaceQuizMutationProps) => {
      const { workplaceId } = props;

      const response = await get({
        url: getWorkplaceQuizUrl(workplaceId),
        queryParams: getWorkplaceQuizQueryParams(),
        requestSchema: getWorkplaceQuizRequestSchema,
        responseSchema: getWorkplaceQuizResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_QUIZ_FAILURE,
      userErrorMessage:
        "Something went wrong while loading the quiz. Please retry or contact support if the error persists.",
    },
  });
}
